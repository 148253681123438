import React from 'react';

import NotFoundLayout from '../components/layouts/NotFoundLayout';

export default function NotFound() {
	return (
		<NotFoundLayout>
			<main>
				<div className="isolate bg-yellow py-10">
					<div className="container">
						<div className="grid grid-cols-1">
							<div className="flex items-center justify-center">
								<div className="rounded-full border-4 flex items-center justify-center border-black w-40 h-40">
									<h1>
										404
									</h1>
								</div>
							</div>

							<div className="flex items-center justify-center py-8">
								<h3>Looks like this page doesn't exist - sorry about that.</h3>
							</div>

						</div>
					</div>
				</div>
			</main>
		</NotFoundLayout>
	)
}
